@use "./../abstracts" as *;

// scrollbar
$custom-scrollbar: false;
$custom-scrollbar-width: 6px;
$custom-scrollbar-border-radius: 5px;

:root {
  @each $color, $value in $colors {
    --#{$color}: #{$value};
    --#{$color}-dark: #{darken($value, 15)};
    --#{$color}-darken: #{darken($value, 20)};
  }

  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  // Section Spacing Custom Properties
  --spacing: clamp(3.75rem, 2.625rem + 5.625vw, 9.375rem);
  --spacing-rounded: clamp(1.875rem, 1.35rem + 2.625vw, 4.5rem);
}

// scrollbar
@if ($custom-scrollbar) {
  *::-webkit-scrollbar {
    width: $custom-scrollbar-width;
    background: none;
  }

  *::-webkit-scrollbar-track {
    background-color: #f3f4f7;
    margin: 0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: $custom-scrollbar-border-radius;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary);
  }
}
