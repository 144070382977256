@use "./../abstracts/colors" as c;
@use "./../abstracts/mixins/breakpoints" as *;

$color: map-get(c.$colors, "white");
$bg: map-get(c.$colors, "second");

// Padding
$pt: clamp(3.125rem, 0.975rem + 10.75vw, 13.875rem);
$pb: 2.5rem;

// --- copyright
$copyright-pt: 1.5rem;

footer {
  --bg: #{$bg};
  background-color: $bg;
  color: $color;

  position: relative;

  padding: {
    top: $pt;
    bottom: $pb;
  }

  &:before {
    content: "";

    background-color: var(--bg);

    position: absolute;
    top: calc(calc(var(--spacing-rounded) * -1) + 1px);

    width: 100%;
    height: var(--spacing-rounded);

    pointer-events: none;
    z-index: -1;
  }

  .footer {
    &__grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem 1rem;

      margin-bottom: clamp(3.75rem, 2.5rem + 6.25vw, 10rem);

      @include breakpoint-min-md {
        gap: 4rem;
      }

      @include breakpoint-min-xxl {
        grid-template-columns: auto auto;
        justify-content: space-between;
      }
    }

    &__lt,
    &__rt {
      display: grid;
      grid-template-columns: 1fr;

      gap: 2rem 4.5rem;

      @include breakpoint-min-md {
        gap: 2rem;
        grid-template-columns: 1fr 1fr;
      }

      @include breakpoint-min-lg {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__lt {
      @include breakpoint-min-xxl {
        justify-content: start;
        grid-template-columns: auto 1fr;
      }
    }

    &__rt {
      @include breakpoint-max-lg {
        .footer--btn-end {
          grid-column-start: 1;
          grid-column-end: -1;
        }
      }

      @include breakpoint-min-xxl {
        gap: 2rem 0.5rem;
        justify-content: end;
        grid-template-columns: 278px 278px auto;
      }
    }

    &__social {
      display: flex;
      align-items: center;

      gap: 2.5rem;

      padding: 0;
      margin: 0;
      list-style: none;

      @include breakpoint-max-md {
        justify-content: center;
      }

      li {
        margin-bottom: 0;
        transition: 0.2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &__list {
      --list-gap: unset;

      display: flex;
      flex-direction: column;
      gap: var(--list-gap);

      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__copyright {
      border-top: 1px solid map-get(c.$colors, "white");
      padding-top: $copyright-pt;
    }
  }
}
