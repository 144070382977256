@use "./../../abstracts/font/fs" as fs;
@use "./../../abstracts/colors" as *;

$fs: #{map-get(fs.$fs, 7)};
$background-image: null;
$marker-color: map-get($colors, "third");

// Ul Sizes
$small-fs: #{map-get(fs.$fs, 8)};
$small-lh: 1.3333333333333333em;

// Marker Circle
$circle-check: url(./../img/ul-check-circle.svg);
$circle-pl: 1.25rem;
$circle-mb: 1.25rem;

// Marker Square
$square-check: url(./../img/ul-check-square.svg);
$square-pl: 3.75rem;

ul {
  font-size: $fs;
  list-style-type: square;

  li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &.ul-spacing-md {
    li:not(:last-child) {
      margin-bottom: 1.875rem;
    }
  }

  li::marker {
    color: $marker-color;
  }

  &.marker--check-circle {
    list-style: $circle-check;

    > li {
      padding-left: $circle-pl;
      margin-bottom: $circle-mb;
    }
  }

  &.marker--check-square {
    list-style: none;

    padding-left: $square-pl;

    > li {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      position: relative;

      &:before {
        content: $square-check;
        display: inline-block;

        position: absolute;
        top: 0;

        height: 34px;

        margin: {
          left: -3.75rem;
          right: 1rem;
        }
      }

      &::marker {
        margin-top: 10px;
      }
    }
  }

  &.marker--bar {
    list-style: "–";

    > li {
      padding-left: 0.25rem;
    }
  }

  // Size
  &.list {
    &--attached {
      li {
        margin-bottom: 0;
      }
    }

    &--sm {
      font-size: $small-fs;
      line-height: $small-lh;
    }

    &--spacing-md {
      li {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
