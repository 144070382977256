@use "./../../abstracts/font" as *;

// TODO: Non funziona map-get($fs, 7);
$label-fs: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
$label-fw: $medium;

.form-check {
  &-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;

    .form-check-label {
      font-size: $label-fs;
      font-weight: $medium;
    }

    > .form-check-label {
      margin-right: auto;
    }

    .form-check-inline:last-child {
      margin-right: 0;
    }
  }

  &-inline {
    margin-right: 3.75rem;
  }

  &-input {
    width: 20px;
    height: 20px;
  }
}
