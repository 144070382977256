@use "./../abstracts/mixins/breakpoints" as *;

$gap: clamp(1.5rem, 0.65rem + 4.25vw, 5.75rem);

.grid {
  &-row {
    --grid-gap: #{$gap};

    display: grid;
    gap: var(--grid-gap);
    align-items: center;

    @include breakpoint-min-lg {
      grid-template-columns: min(50%, 746px) 1fr;

      &.grid--between {
        .grid-col {
          &:first-child {
            margin-left: auto;
          }
        }
      }

      &:not(.grid--reverse) {
        .grid-col:last-child {
          margin-right: 2.5rem;
        }
      }
    }

    @include breakpoint-min-lg {
      &.grid--reverse {
        grid-template-columns: 1fr min(50%, 746px);

        .grid-col {
          &:first-child {
            order: 1;
          }

          &:last-child {
            margin-right: 2.5rem;
          }
        }
      }
    }
  }
}
