@use "./../abstracts" as *;

$bgAfter: rgba(map-get($colors, "black"), 20%);
$bgAfterBlur: 5px;

.hero {
  --hero-img: unset;

  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;

  min-height: 60svh;

  background: {
    image: var(--hero-img);
    position: top center;
    size: cover;
  }

  @include breakpoint-min-md {
    min-height: 100svh;
    background-attachment: fixed;
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;

    // backdrop-filter: blur(#{$bgAfterBlur});

    background-color: $bgAfter;
  }
}
