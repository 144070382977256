@use "./../abstracts/colors" as *;
@use "./../abstracts/mixins/breakpoints" as *;

$nav-link-color: map-get($colors, "white");

$nav1: map-get($colors, "eighth");
$nav2: map-get($colors, "fourth");
$nav3: map-get($colors, "seventh");
$nav4: map-get($colors, "first");
$nav5: map-get($colors, "sixth");

$inverted-bg: map-get($colors, "white");
$inverted-color: map-get($colors, "black");

.navbar {
  padding-block: clamp(1rem, 0.75rem + 1.25vw, 2.25rem);
  box-shadow: unset;

  transition: 0.4s ease-in-out;

  background: linear-gradient(
    0deg,
    rgba(map-get($colors, "black"), 0) 0%,
    rgba(map-get($colors, "black"), 0.6) 100%
  );

  &.scroll-up {
    transform: translateY(0);
  }

  &.scroll-down {
    transform: translateY(-100%);
  }

  &-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;

    @include breakpoint-max-lg {
      display: inline-block;
    }
  }

  &.navbar-inverted {
    background: $inverted-bg;
    border-bottom: 1px solid rgba(map-get($colors, "black"), 0.1);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    .hamburger-menu {
      span {
        background-color: $inverted-color;

        &::before,
        &::after {
          background-color: $inverted-color;
        }
      }

      &.active {
        span {
          background-color: transparent;

          &::before,
          &:after {
            background-color: $inverted-color;
          }
        }
      }
    }

    .nav-link {
      color: $inverted-color;
    }

    .navbar-brand--white {
      opacity: 0;
    }

    .btn.btn-outline-white {
      border-color: $inverted-color;
      color: $inverted-color;
      background-color: rgba($inverted-color, 0.1);

      &:hover {
        color: #fff;
        background-color: #000;
      }
    }
  }

  &-brand {
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include breakpoint-max-lg {
      width: 50%;
    }

    img {
      transition: opacity 0.2s ease-in-out;
    }

    > * {
      grid-area: 1 / 1;
    }
  }

  &-nav {
    @include breakpoint-min-lg {
      align-items: center;
      gap: clamp(2rem, 1.7rem + 1.5vw, 3.5rem);
    }

    .nav {
      &-item {
        margin-bottom: 0;
      }

      &-link {
        color: $nav-link-color;
        border-bottom: 0.1875rem solid transparent;

        padding: 0;

        &.active {
          color: var(--color-nav);
          border-color: var(--color-nav);
        }

        &:hover {
          color: var(--color-nav);
        }

        &.nav-link-1 {
          --color-nav: #{$nav1};
        }

        &.nav-link-2 {
          --color-nav: #{$nav2};
        }

        &.nav-link-3 {
          --color-nav: #{$nav3};
        }

        &.nav-link-4 {
          --color-nav: #{$nav4};
        }

        &.nav-link-5 {
          --color-nav: #{$nav5};
        }
      }
    }
  }

  &-toggler {
    padding: 0;
    border: 0;
  }
}
