// Clamp Calculator - https://clamp.font-size.app/
// Minimum viewport width: 320
// Maximum viewport width: 1440

// Line Height Calculator - https://codepen.io/indigo-szarouski/pen/xdRvjX

$fs: (
  1: clamp(1.875rem, 1.15rem + 3.625vw, 5.5rem),
  2: clamp(1.75rem, 1.2rem + 2.75vw, 4.5rem),
  3: clamp(1.625rem, 1.2rem + 2.125vw, 3.75rem),
  4: clamp(1.5rem, 1.2rem + 1.5vw, 3rem),
  5: clamp(1.375rem, 1.25rem + 0.625vw, 2rem),
  6: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem),
  7: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem),
  8: clamp(1rem, 0.975rem + 0.125vw, 1.125rem),
  9: 1rem,
);

$title-1: map-get($fs, 1);
$title-2: map-get($fs, 2);
$title-3: map-get($fs, 3);
$title-4: map-get($fs, 4);
$title-5: map-get($fs, 5);
$title-6: map-get($fs, 6);
$title-7: map-get($fs, 7);
$title-8: map-get($fs, 8);
