@use "./../../abstracts/colors" as c;
@use "./../../abstracts/font" as *;
@use "./../../abstracts/mixins/buttons" as *;
@use "./../../abstracts/mixins/breakpoints" as *;

$color: map-get(c.$colors, "second");
$bg: transparent;

$min-h: 5.5625rem;

$gap: 1.25rem;

$pr: clamp(1rem, 0.6625rem + 1.6875vw, 2.6875rem);
$pl: clamp(1rem, 0.6625rem + 1.6875vw, 2.6875rem);

$fs: clamp(1.125rem, 0.975rem + 0.75vw, 1.875rem);
$fw: $bold;

$b-color: map-get(c.$colors, "second");
$b-radius: 1.5rem;

$hover-color: map-get(c.$colors, "white");
$hover-bg: map-get(c.$colors, "second");

.btn.btn-value {
  @include create-btn(
    $color: $color,
    $bg: $bg,

    $gap: $gap,

    $pr: $pr,
    $pl: $pl,

    $min-height: $min-h,
    $b-radius: $b-radius,
    $b-color: $b-color,

    $fs: $fs,
    $fw: $fw,

    $hover-color: $hover-color,
    $hover-bg: $hover-bg
  );

  @include breakpoint-max-md {
    // align-items: flex-start;
    justify-content: flex-start;
  }

  > * {
    font: {
      size: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
      weight: normal !important;
    }

    transform: unset;
    transition: unset;
  }

  &:hover {
    transform: inherit;
  }
}
