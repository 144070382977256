@use "./../abstracts/mixins/breakpoints" as *;

.clipped {
  &__svg {
    position: absolute;
    width: 0;
    height: 0;
  }

  &__spacer {
    @include breakpoint-max-lg {
      min-height: 110px;
    }
  }

  &__wrapper {
    display: grid;

    position: absolute;
    left: 0;
    top: 0;

    @include breakpoint-max-lg {
      width: 100%;
      min-height: 140px;
    }

    @include breakpoint-min-lg {
      right: 50%;
      bottom: 0;
    }

    > * {
      grid-area: 1/1;
    }
  }

  &__container {
    display: grid;
    place-items: center;

    > * {
      margin-bottom: 0;
    }
  }

  &__path {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 224, 36, 0) 0%,
      rgba(255, 224, 36, 1) 100%
    );
    background-size: cover;
    pointer-events: none;

    -webkit-clip-path: url(#my-clip-path-mobile);
    clip-path: url(#my-clip-path-mobile);

    @include breakpoint-min-lg {
      -webkit-clip-path: url(#my-clip-path-desktop);
      clip-path: url(#my-clip-path-desktop);
    }
  }
}

.clipped__test {
  min-height: 350px;
  // position: relative;
}
