@use "./../../abstracts/font" as *;

.title {
  // --- General Property
  &-1,
  &-2,
  &-3,
  &-4,
  &-5 {
    font: {
      family: $ff-2;
      weight: $bold;
    }
  }

  &-6,
  &-7,
  &-8,
  &-9 {
    font: {
      family: $ff-1;
      weight: $regular;
    }
  }

  // --- Single Property
  &-1 {
    font: {
      size: map-get($fs, 1);
    }

    line-height: 1.1363636363636365em;
  }

  &-2 {
    font: {
      size: map-get($fs, 2);
    }

    line-height: 1.3888888888888888em;
  }

  &-3 {
    font: {
      size: map-get($fs, 3);
    }

    line-height: 1.0833333333333333em;
  }

  &-4 {
    font: {
      size: map-get($fs, 4);
    }

    line-height: 1.25em;
  }

  &-5 {
    font: {
      size: map-get($fs, 5);
    }

    line-height: 1.25em;
    letter-spacing: -0.0625em;
  }

  &-6 {
    font: {
      size: map-get($fs, 6);
    }

    line-height: 1.3333333333333333em;
  }

  &-7 {
    font: {
      size: map-get($fs, 7);
    }
  }

  &-8 {
    font: {
      size: map-get($fs, 8);
    }
  }

  &-9 {
    font: {
      size: map-get($fs, 9);
    }
  }
}
