@use "./../../abstracts/font/fs" as fs;

$fs: map-get(fs.$fs, 7);
$lh: 1.3em;

$big-fs: map-get(fs.$fs, 6);
$big-lh: 1.4166666666666667em;

p {
  font-size: $fs;
  line-height: $lh;

  &.big {
    font-size: $big-fs;
    line-height: $big-lh;
  }
}
