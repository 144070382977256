@use "./../abstracts/colors" as *;
@use "./../abstracts/mixins/breakpoints" as *;
@use "./../abstracts/font" as *;

$title-fs: $title-3;
$title-fw: $bold;

$timeline-primary-color: map-get($colors, "second");

.timeline {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  opacity: 0;

  position: relative;

  --timeline-progress: 0%;

  padding-top: 5rem;

  @include breakpoint-min-lg {
    padding-top: 10.5rem;
    gap: 10rem;
  }

  &:before,
  &:after {
    content: "";

    width: 5px;

    position: absolute;
    top: 0;
    transform: translateX(-50%);

    @include breakpoint-min-lg {
      left: 50%;
    }
  }

  &:before {
    width: 2px;
    height: calc(100% - var(--timeline-height-last-child));

    background: linear-gradient(
      0deg,
      rgba($timeline-primary-color, 0) 0%,
      rgba($timeline-primary-color, 1) 80%
    );
  }

  &:after {
    background-color: $timeline-primary-color;
    height: var(--timeline-progress);
  }

  &__row {
    --timeline-point-opacity: 0;

    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    position: relative;

    @include breakpoint-max-lg {
      margin-left: -1rem;
      padding-left: 3rem;
    }

    @include breakpoint-min-lg {
      gap: 6.25rem;
      grid-template-columns: repeat(2, 1fr);
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 1rem;

      transform: translateX(-50%);

      width: 1.5rem;
      height: 1.5rem;

      border: 6px solid $timeline-primary-color;

      background-color: map-get($colors, "white");
      border-radius: 50%;
      z-index: 1;

      opacity: var(--timeline-point-opacity);

      @include breakpoint-min-lg {
        left: 50%;

        width: 1.75rem;
        height: 1.75rem;
      }
    }

    &:nth-child(even) {
      .timeline__col {
        &:first-child {
          text-align: left;
        }

        &:last-child {
          @include breakpoint-min-lg {
            order: -1;
            text-align: right;
          }
        }
      }
    }

    &:last-child {
      background-color: var(--bg);
      z-index: 1;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    order: 0;

    opacity: 0;
    transform: translateY(20%);

    @include breakpoint-min-lg {
      gap: 2.5rem;
    }

    > * {
      margin-bottom: 0;
    }

    &:first-child {
      @include breakpoint-min-lg {
        text-align: right;
      }
    }
  }

  &__title {
    font: {
      size: $title-fs;
      weight: $title-fw;
    }

    line-height: 1;
  }
}
