/* Menu Button 1 */
.hamburger-menu {
  width: 2.5rem;
  height: 1.8rem;
  cursor: pointer;

  span {
    &,
    &::before,
    &::after {
      background: #fff;
      border-radius: 3px;
      content: "";
      position: absolute;
      width: 2.5rem;
      height: 0.25rem;
      margin-top: 13px;

      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }

    &::before {
      margin-top: -12px;
    }

    &::after {
      margin-top: 12px;
    }
  }

  &.active {
    span {
      background: transparent;

      &::before {
        margin-top: 0;

        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &::after {
        margin-top: 0;

        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}
