@use "./../abstracts/mixins/breakpoints" as *;
@use "./../abstracts/colors" as *;

// --- Card
// Padding
$pt: clamp(1rem, 0.7rem + 1.5vw, 2.5rem);
$pr: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);
$pb: clamp(1rem, 0.8rem + 1vw, 2rem);
$pl: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);

// Border
$b-width: 1px;

// --- Rounded
$rounded: 1.5rem;

// --- Card Theme Primary
$primary-color: map-get($colors, "white");
$primary-bg: transparent;
$primary-b-color: map-get($colors, "white");

$primary-hover-color: map-get($colors, "second");
$primary-hover-bg: map-get($colors, "first");
$primary-hover-b-color: map-get($colors, "first");

// --- Card Theme Secondary
$secondary-color: map-get($colors, "second");
$secondary-bg: transparent;
$secondary-b-color: map-get($colors, "second");
$secondary-p: $pt;
$secondary-b-size: 2px;

// --- Card Theme tertiary
$tertiary-bg: map-get($colors, "white");
$tertiary-b-size: 2px;
$tertiary-b-color: map-get($colors, "second");
$tertiary-px: clamp(0.5rem, 0.3rem + 1vw, 1.5rem);
$tertiary-pt: clamp(1rem, 0.8rem + 1vw, 2rem);
$tertiary-pb: clamp(1.25rem, 1rem + 1.25vw, 2.5rem);
$tertiary-header-pl: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
$tertiary-header-pr: 0.5rem;
$tertiary-body-mb: clamp(1.5rem, 1.2rem + 1.5vw, 3rem);

// --- Card Theme quaternary
$quaternary-b-size: 2px;
$quaternary-b-color: map-get($colors, "first");
$quaternary-py: clamp(1.5rem, 1.3rem + 1vw, 2.5rem);
$quaternary-px: clamp(1rem, 0.8rem + 1vw, 2rem);
$quaternary-header-color: map-get($colors, "first");
$quaternary-hover-color: map-get($colors, "white");
$quaternary-hover-bg: map-get($colors, "first");

// --- Card Theme quinary
$quinary-bg: rgba(map-get($colors, "sixth"), 10%);
$quinary-b-size: 0;
$quinary-py: clamp(1.5625rem, 1.25rem + 1.5625vw, 3.125rem);
$quinary-px: clamp(1rem, 0.7rem + 1.5vw, 2.5rem);

// --- Card BG
$card-bg: false;

// --- Card Medium
$medium-px: clamp(1rem, 0.4125rem + 2.9375vw, 3.9375rem);
$medium-py: clamp(2rem, 1.15rem + 4.25vw, 6.25rem);
$medium-py-end: clamp(2rem, 0.7375rem + 6.3125vw, 8.3125rem);
$medium-radius: 2rem;

// --- Card Big
$big-px: clamp(1rem, -0.3rem + 6.5vw, 7.5rem);
$big-py: clamp(1.5rem, 1rem + 2.5vw, 4rem);
$big-radius: clamp(1.5rem, 1.4rem + 0.5vw, 2rem);

.card {
  overflow: hidden;
  position: relative;
  z-index: 0;

  height: 100%;

  border-width: $b-width;

  padding: $pt $pr $pb $pl;

  &,
  .card__icon svg path {
    transition: 0.4s all ease-in-out;
  }

  &--min-h {
    &-1,
    &-2 {
      min-height: 250px;

      display: grid;
      place-items: center;
    }

    @include breakpoint-min-md {
      &-1 {
        min-height: 325px;
      }

      &-2 {
        min-height: 285px;
      }
    }
  }

  &--rounded {
    border-radius: $rounded;
  }

  // Size
  &--md {
    border-radius: $medium-radius;

    padding: {
      inline: $medium-px;

      block: $medium-py;
      block-end: $medium-py-end;
    }
  }

  &--big {
    border-radius: $big-radius;

    padding: {
      inline: $big-px;
      block: $big-py;
    }
  }

  // Card Themes
  &--theme {
    &-primary {
      color: $primary-color;
      background-color: $primary-bg;
      border-color: $primary-b-color;

      &:hover {
        color: $primary-hover-color;
        background-color: $primary-hover-bg;
        border-color: $primary-hover-b-color;

        box-shadow: 0 0 20px rgba(map-get($colors, "black"), 20%);

        path {
          fill: $primary-hover-color;
        }
      }
    }

    &-secondary {
      color: $secondary-color;
      background-color: $secondary-bg;
      border: {
        width: $secondary-b-size;
        color: $secondary-b-color;
      }

      padding: $secondary-p;

      a {
        font-size: 1.125rem;
      }

      &.fog {
        &--1 {
          --fog: conic-gradient(
            from 1.5708rad at 50% 50%,
            #ffffff 59%,
            #e1f0f7 74%
          );

          --blur: blur(100px);
        }

        &--2 {
          --fog: linear-gradient(180deg, #e1f0f7 0%, #fafbfb 100%);
        }

        &--3 {
          --fog: radial-gradient(circle at 32% 78%, #ffffff 0%, #e1f0f7 94%);
        }
      }

      &:after {
        content: "";

        position: absolute;
        inset: 0;

        width: 100%;
        height: 100%;

        z-index: -1;

        background-image: var(--fog);
        filter: var(--blur);
      }
    }

    &-tertiary {
      background-color: $tertiary-bg;

      border: {
        color: $tertiary-b-color;
        width: $tertiary-b-size;
      }

      padding: {
        right: $tertiary-px;
        left: $tertiary-px;

        top: $tertiary-pt;
        bottom: $tertiary-pb;
      }

      .card {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;

          padding: {
            left: $tertiary-header-pl;
            right: $tertiary-header-pr;
          }

          margin-bottom: clamp(3.1875rem, 2.55rem + 3.1875vw, 6.375rem);

          .icons-list {
            display: flex;
            align-items: center;
            gap: 1rem;

            margin-bottom: auto;

            > img {
              width: clamp(1.625rem, 1.4875rem + 0.6875vw, 2.3125rem);
              height: auto;
            }
          }
        }

        &__body {
          &:not(:last-child) {
            margin-bottom: $tertiary-body-mb;
          }
        }

        &__footer {
          margin-top: auto;
        }
      }

      &:after {
        z-index: -1;
      }

      &.fog--1 {
        &:after {
          top: -20%;
          left: -50%;
        }
      }

      &.fog--2 {
        &:after {
          bottom: -20%;
          right: -50%;
        }
      }

      &:after {
        content: "";

        position: absolute;
        width: 511px;
        height: 345px;

        background-color: #ff72d7;

        transform: skewX(20deg);

        filter: blur(70px);
        opacity: 0.2;
      }
    }

    &-quaternary {
      padding: $quaternary-py $quaternary-px;

      border: {
        width: $quaternary-b-size;
        color: $quaternary-b-color;
      }

      .card__header {
        color: $quaternary-header-color;

        transition: 0.4s ease-in-out;
      }

      &.blur {
        &--1:after {
          right: -50%;
          top: -50%;
        }

        &--2:after {
          left: -50%;
          top: -50%;
        }

        &--3:after {
          left: -50%;
          bottom: -50%;
        }

        &--4:after {
          right: -50%;
          bottom: -50%;
        }
      }

      &:after {
        content: "";

        width: 100%;
        height: auto;
        aspect-ratio: 1;

        border-radius: 50%;
        position: absolute;
        z-index: -1;

        filter: blur(60px);

        background: radial-gradient(
          circle,
          rgba(map-get($colors, "first"), 0.45) 0%,
          rgba(map-get($colors, "white"), 0) 100%
        );
      }

      &:hover {
        color: $quaternary-hover-color;
        background-color: $quaternary-hover-bg;

        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);

        transform: scale(1.03);

        .card__header {
          color: $quaternary-hover-color;
        }
      }
    }

    &-quinary {
      background-color: $quinary-bg;
      border: $quinary-b-size;

      padding: {
        block: $quinary-py;
        inline: $quinary-px;
      }
    }
  }

  // Card BG
  @if $card-bg {
    .card__bg {
      --card-bg-width: 40%;
      --card-bg-top: 0;
      --card-bg-right: unset;
      --card-bg-left: unset;
      --card-bg-transform: unset;

      width: var(--card-bg-width);
      height: 100%;

      position: absolute;
      z-index: -1;

      top: var(--card-bg-top);
      right: var(--card-bg-right);
      left: var(--card-bg-left);

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;

        transform: scale(var(--card-bg-transform));
      }

      &.card__bg--1 {
        --card-bg-right: -10%;
        --card-bg-transform: 1.2;
      }

      &.card__bg--2 {
        --card-bg-top: -10%;
        --card-bg-right: -15%;
      }

      &.card__bg--3 {
        --card-bg-right: -12%;
        --card-bg-transform: 1.2;
      }

      &.card__bg--4 {
        --card-bg-right: -10%;
        --card-bg-transform: 1.3;
        --card-bg-width: 30%;
      }

      &.card__bg--5 {
        --card-bg-right: -10%;
        --card-bg-transform: 1.4;
      }

      &.card__bg--6 {
        --card-bg-width: 90%;
        --card-bg-top: -20%;
        --card-bg-left: -20%;
        --card-bg-transform: 1.7;
      }

      &.card__bg--7 {
        --card-bg-width: 50%;
        --card-bg-top: -20%;
        --card-bg-right: -10%;
        --card-bg-transform: 1.7;
      }

      &.card__bg--8 {
        --card-bg-width: 70%;
        --card-bg-right: -5%;
        --card-bg-transform: 1.2;
        --card-bg-top: 15%;
      }
    }
  }
}
