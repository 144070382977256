.theme-white {
  &.form-floating {
    > label {
      color: rgba(#fff, 60%);

      &::after {
        background-color: transparent !important;
      }
    }

    .form-control {
      background-color: transparent;

      color: white;

      border-width: 0 0 1px 0;
      border-color: white;

      &:focus {
        & ~ label {
          color: rgba(#fff, 80%);

          transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
        }
      }

      &:not(:placeholder-shown) ~ label {
        color: rgba(#fff, 80%);
      }
    }

    .form-select {
      --bs-form-select-bg-img: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGQ9Ik0uMzMzIDIwaDIwVjBoLTIweiIvPgogICAgPHBhdGggc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIiIGQ9Im0xLjYyMiA2IDguNSA4LjUgOC41LTguNSIvPgogIDwvZz4KPC9zdmc+");

      background-color: transparent;
      background-size: 20px 20px;
      color: white;
      font-weight: 500;

      border: {
        left: 0;
        right: 0;
        top: 0;
      }

      + label {
        opacity: 0;
      }
    }
  }

  &.form-check-group {
    color: white;

    .form-check {
      .form-check-input {
        &,
        &:checked {
          border-color: white;
          background-color: transparent;
        }

        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(#fff, 0.25);
        }
      }
    }
  }
}
