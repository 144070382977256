@use "./../abstracts/font" as *;
@use "./../abstracts/colors" as *;

$button-fs: $title-6;
$button-ff: $ff-2;
$color: map-get($colors, "second");

$accordion-fs: $title-8;

.accordion {
  // Collapse Icon
  --bs-accordion-btn-icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGQ9Ik0uMzMzIDIwaDIwVjBoLTIweiIvPgogICAgPHBhdGggc3Ryb2tlPSIjMDcyMjNFIiBzdHJva2Utd2lkdGg9IjIiIGQ9Im0xLjYyMiA2IDguNSA4LjUgOC41LTguNSIvPgogIDwvZz4KPC9zdmc+");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGQ9Ik0uMzMzIDIwaDIwVjBoLTIweiIvPgogICAgPHBhdGggc3Ryb2tlPSIjMDcyMjNFIiBzdHJva2Utd2lkdGg9IjIiIGQ9Im0xLjYyMiA2IDguNSA4LjUgOC41LTguNSIvPgogIDwvZz4KPC9zdmc+");
  --bs-accordion-btn-icon-width: 1.25rem;

  &-button {
    font: {
      size: $button-fs;
      weight: $bold;
      family: $button-ff;
    }

    &:focus {
      box-shadow: unset;
    }
  }

  &-item {
    &:not(:first-of-type),
    &:first-of-type {
      border-top-width: 1px;
    }

    border: {
      color: $color;
      style: solid;
      left: 0;
      right: 0;
    }

    &:first-of-type {
      border-top: {
        width: 1px;

        left-radius: 0;
        right-radius: 0;
      }
    }

    &:last-of-type {
      border-bottom-width: 1px;

      border-bottom: {
        left-radius: 0;
        right-radius: 0;
      }
    }
  }

  &-body {
    padding: 0 0 2rem 0;

    &,
    * {
      font-size: $accordion-fs;
    }
  }

  &-button {
    padding: clamp(1rem, 0.8rem + 1vw, 2rem) 0;

    // Active
    &:not(.collapsed) {
      border-top: 1px solid;

      color: $color;
      background-color: transparent;
      box-shadow: unset;
    }
  }
}
