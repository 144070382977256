@use "./../abstracts" as *;

@use "./../../../node_modules/bootstrap/scss/bootstrap-grid.scss" with (
  $grid-gutter-width: 2rem,
  $container-max-widths: (
    xxl: calc(1680px + 2rem),
  )
);

@mixin make-container($padding-x: 2.5rem) {
  max-width: calc(1100px + calc($padding-x * 2));
  padding-right: calc($padding-x / 2);
  padding-left: calc($padding-x / 2);
  margin: {
    left: auto;
    right: auto;
  }
}

.container-close {
  @include make-container();
}

.row {
  &.gutter-x--md {
    --bs-gutter-x: 3.75rem;
  }
}
