@use "./../../abstracts/mixins/buttons" as *;
@use "./../../abstracts/font" as *;

// Font
// $fs: map-get($fs, 6); Non funziona
$ff: $ff-1;
$fs: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
$fw: $medium;
$lh: 1.3333333333333333em;

// Text Decoration
$td: underline;

$gap: 0.625rem;

.link.link-download {
  @include create-link($ff: $ff, $fs: $fs, $fw: $fw, $gap: $gap);

  text-decoration: $td;
  line-height: $lh;

  text-underline-offset: 2px;

  display: inline-flex;
  align-items: flex-start;
  gap: 0.625rem;

  &:before {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxOCI+CiAgPGcgZmlsbD0iIzQ4NUM2RCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgIDxwYXRoIGQ9Ik0xNi4wNTUgMTEuODQ4YS45NDcuOTQ3IDAgMCAwLS45NDQuOTQydjIuMjZhLjU2NS41NjUgMCAwIDEtLjU2Ny41NjZIMi40NTVhLjU2OC41NjggMCAwIDEtLjU2Ni0uNTY1VjEyLjc5YS45NDIuOTQyIDAgMCAwLS40NzItLjgxNi45NDcuOTQ3IDAgMCAwLS45NDUgMEEuOTQyLjk0MiAwIDAgMCAwIDEyLjc5djIuMjZjMCAuNjUuMjYgMS4yNzMuNzIgMS43MzIuNDYuNDU5IDEuMDg1LjcxNyAxLjczNS43MThoMTIuMDljLjY1IDAgMS4yNzUtLjI2IDEuNzM1LS43MTguNDYtLjQ2LjcyLTEuMDgyLjcyLTEuNzMxVjEyLjc5YS45NDMuOTQzIDAgMCAwLS45NDQtLjk0MloiLz4KICAgIDxwYXRoIGQ9Ik04LjQ1OS41YS45NDcuOTQ3IDAgMCAwLS45NDYuOTQ1djguNTYzTDQuNTI3IDcuNTJhLjk0Ny45NDcgMCAwIDAtMS4zMzEuMTIuOTQ1Ljk0NSAwIDAgMCAuMTIgMS4zM2w0LjUzOCAzLjc4YS45NjQuOTY0IDAgMCAwIC4xNS4wODdjLjAzNS4wMjMuMDcyLjA0NC4xMDkuMDYyLjIyLjA5My40NjkuMDkzLjY4OSAwYS45NzcuOTc3IDAgMCAwIC4xMDYtLjA2MS45ODQuOTg0IDAgMCAwIC4xNTEtLjA4OGw0LjUzNy0zLjc3OWEuOTQ1Ljk0NSAwIDAgMC0xLjIwNi0xLjQ1MWwtMi45ODYgMi40ODdWMS40NDVBLjk0Ni45NDYgMCAwIDAgOC40NTkuNVoiLz4KICA8L2c+Cjwvc3ZnPgo=);
  }
}
