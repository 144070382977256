@use "./../../abstracts/font/fs" as fs;
@use "./../../abstracts/font/ff" as ff;
@use "./../../abstracts/font/fw" as fw;
@use "./../../abstracts/mixins/buttons" as *;

// Layout
$min-width: null;
$min-height: 3.5rem;
$gap: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);

// Padding
$pt: 0.5rem;
$pr: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
$pb: 0.5rem;
$pl: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);

// Border
$b-size: 0.125rem;
$b-radius: 0;

// Font
$ff: ff.$ff-2;
$fs: map-get(fs.$fs, 8);
$fw: fw.$bold;

.btn {
  @include create-btn(
    $flex: true,
    $gap: $gap,
    $min-width: $min-width,
    $min-height: $min-height,
    $pt: $pt,
    $pr: $pr,
    $pb: $pb,
    $pl: $pl,
    $b-size: $b-size,
    $b-radius: $b-radius,
    $ff: $ff,
    $fs: $fs,
    $fw: $fw
  );

  &:hover {
    * {
      transform: translateX(0.2rem);
    }
  }

  * {
    // margin-left: -0.5rem;
    transition: 0.2s ease-in-out;
    font-weight: inherit !important;
  }
}
