@use "./../abstracts/colors" as *;
@use "./../abstracts/font" as *;

$link-color: map-get($colors, "second");
$link-tt: uppercase;
$link-fw: $bold;
$link-fs: $title-8;
$link-lh: 1.3333333333333333em;
$link-px: 0.9375rem;
$link-pt: 0;
$link-pb: 0.6875rem;

.nav {
  &-tabs {
    border-bottom: 0;
    gap: 1rem 3.125rem;

    .nav-link {
      font: {
        size: $link-fs;
        weight: $link-fw;
      }

      color: $link-color;
      border: {
        width: 0 0 2px 0;
        style: solid transparent;
      }

      text-transform: $link-tt;
      line-height: $link-lh;

      padding: $link-pt $link-px $link-pb;

      &:hover {
        border-color: rgba($link-color, 20%);
      }

      &.active {
        color: $link-color;
        border-color: $link-color;
      }
    }
  }
}
