$icomoon-font-family: "proenergy" !default;
$icomoon-font-path: "../fonts/proenergy" !default;

$icon-energy: "\e900";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?cbmmac");
  src:
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?cbmmac#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?cbmmac")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?cbmmac")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?cbmmac##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-energy {
  &:before {
    content: $icon-energy;
  }
}
