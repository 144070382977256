@use "./../abstracts/mixins/breakpoints" as *;

.btn-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem clamp(0.5rem, 0.1rem + 2vw, 2.5rem);

  @include breakpoint-max-md {
    flex-direction: column;
  }

  > .btn {
    flex: 1;
  }
}
